import { gql } from 'apollo-angular';

// We use the gql tag to parse our query string into a query document
export const GET_POSTS = gql`
query{
  testScoreRanges{
      testId,
      scoreRangeId
  }
}
`;

export const GET_TENANTS_BY_ID = gql`
  query($tenantId: Int!){ 
    tenantDataById(tenantId: $tenantId){
      tenantID,
      themeID,
      logoUploadID,
      name,
      description,
      licenseDate,
      licenseLengthInMonths,
      identifier,
      clientSecret,
      clientID,
      tenantLogo,
      tenantLogoType,
      logoUploadID,
      isIDMDependent,
      isMaine,
      themeID,
      theme,
      timeZoneID,
      timeZoneName,
      beginner,
      developing,
      proficient,
      distinguished,
      beginnerText,
      developingText,
      proficientText,
      distinguishedText,
      scoringType,
      qTILayout,
      
      readAloud,
      stdentToStudentMessage,
      zoomApiKey,
      zoomApiSecret,
      pointFullCourseKey,
      pointFullCourseSecret,
      isUserProfileAttributes,
      isELTenant,
      isSelfManaged,
      activeSchoolYear,
      lTAssessmentWgt,
      lTAssignmentWgt,
      lTDiscussionWgt,
      lTExternalToolWgt,
      lTPointfulWgt,
      performanceType,
      isEdisonLearningRedirect,
      isUseLogoAndNameAsHeader,
      stateID,
      countryID
    }
  }
`;

export const GET_TENANTS = gql`
 query($pageNumber: Int!, $pageSize: Int!,$search: String!){
  tenants(
     filter: {
       pageNumber: $pageNumber
       pageSize: $pageSize
     }
     search: $search
   ){
     pageNumber,
     pageSize,
     totalPages,
     totalRecords,
     data{
       tenantID,
       logoUploadID,
       isIDMDependent,
       isMaine,
       themeID,
       theme,
       timeZoneID,
       timeZoneName,
       beginner,
       developing,
       proficient,
       name,
       distinguished,
       beginnerText,
       developingText,
       proficientText,
       distinguishedText,
       scoringType,
       qTILayout,
      
       readAloud,
       stdentToStudentMessage,
       description,
       zoomApiKey,
       zoomApiSecret,
       pointFullCourseKey,
       pointFullCourseSecret,
       isUserProfileAttributes,
       isELTenant,
       isSelfManaged,
       activeSchoolYear,
       lTAssessmentWgt,
       lTAssignmentWgt,
       licenseDate,
       lTDiscussionWgt,
       lTExternalToolWgt,
       lTPointfulWgt,
       performanceType,
       isEdisonLearningRedirect,
       isUseLogoAndNameAsHeader,
       stateID,
       countryID,
       licenseLengthInMonths,
       identifier,
       clientSecret,
       clientID,
       tenantLogo,
       tenantLogoType
     }
   }   
 }
`;

export const GET_USERS_BY_TENANTID = gql`
 query($tenantId: Int!){
  usersByTenantId(tenantId: $tenantId){        
      userProfileID,
      firstName,
      lastName,
      userName,
      email,
      tenantID,
      schoolID,
      isDistrictAdmin,
      isSchoolAdmin,
      isCourseDesigner,
      studentID,
      districtUserID,
      teacherID     
   }
 }
`;

export const GET_LP_GOALINSTANCES = gql`
query($communityId: Int!, $userProfileID:Int!){
  lPGoalInstances(communityId: $communityId, userProfileID:$userProfileID){        
    communityID,
    goalInstanceID,
    parentGoalInstanceID,
    orderIndex,
    name,
    hexcolor,
    scorePercentage,
    performancePercentage,
    description ,
    positionLeft,
    positionTop   
   }
 }
`;
export const GET_ADMIN_LP_GOALS = gql`
query($communityId: Int!){
  adminLPGoals(communityId: $communityId){        
    communityId,
    goalId,
    parentGoalId,
    orderIndex,
    name,
    description,
    hexcolor,
    positionLeft,
    positionTop
 }
 }
`;
export const GET_CLASSROOMS_BY_SCHOOLID = gql`
 query($schoolId: Int!){
  classroomsBySchoolId(schoolId: $schoolId){        
    classroomID,
    name,
    description,
    schoolID       
   }
 }
`;

export const GET_COURSESECTIONS = gql`
 query($yID: Int!,$tenantId: Int!){
  courseSections(yID: $yID,tenantId: $tenantId){        
    courseSectionID,
    yearLID,
    name,
    description,
    isActive,
    tenantID,
    examPercentage,
    testPercentage,
    quizPercentage,
    assignmentPercentage,
    discussionPercentage,
    participationPercentage
    
          
   }
 }
`;

export const GET_CLASSROOMS = gql`
 query($schoolId: Int!){
  classroomsBySchoolId(schoolId: $schoolId){        
    classroomID,
    name,
    description,
    schoolID       
   }
 }
`;
export const GET_CLASSROOMS_BY_STUDENTID = gql`
 query($stdID: Int!){
  studentClassroomsByStudentId(stdID: $stdID){        
    classroomID,
    name,
    description,
    schoolID       
   }
 }
`;
export const GET_IMPERSONATE_AS_LIST = gql`
 query($id: Int!){
  impersonateAsList(id: $id){        
    iD,
    name,
    description           
   }
 }
`;
export const GET_IMPERSONATE_ME_LIST = gql`
 query($id: Int!){
  impersonateMeList(id: $id){        
    iD,
    name,
    description           
   }
 }
`;

export const GET_TEACHERCLASSROOM_COURSESECTIONS = gql`
 query($teacID: Int!,$year: String!){
  teacherClassroomCourseSections(teacID: $teacID,year: $year){        
    teacherClassroomCourseSectionID,
    classroomID,
    courseSectionID,
    description,
    eLCourseID,
    eLSectionID,
    eLCourseName,
    isELTCC,    
    courseName,
    className,
    pLPStatus,
    lTStatus,
    eLStatus,
    isELTenant,
    isLTTenant, 
    isACTenant,
    isPLPDisabled  
   }
 }
`;

export const GET_CLASSROOMSTUDENTS = gql`
 query($sClassroomId: Int!,$schoolId: Int!,$pagesize: Int!,$pagenumber: Int!,$userfilter : String!){
  classroomStudents(sClassroomID: $sClassroomId,schoolID: $schoolId,pagesize: $pagesize,pageNumber: $pagenumber,userFilter: $userfilter){        
    recCount,
    pageNumber,
    pageSize ,
    students{
      studentID,
      userProfileID,
      schoolID,
      firstName,
      lastName,
      tenantID,
      username,
      email
    }       
   }
 }
`;

export const GET_SCHOOLTEACHERS = gql`
 query($tSchoolID: Int!,$pagesize: Int!,$pagenumber: Int!,$userfilter : String!){
  schoolTeachers(tSchoolID: $tSchoolID,pagesize: $pagesize,pageNumber: $pagenumber,userFilter: $userfilter){        
    recCount,
    pageNumber,
    pageSize ,
    teachers{
      rOWNUM,
      teacherID,
      userProfileID,
      schoolID,
      firstName,
      lastName,
      tenantID,
      username,
      email,
      isSchoolAdmin,
      isCourseDesigner      
    }       
   }
 }
`;

export const GET_LOOKUPITEMS = gql`
 query($type: String!,$tenantID: Int!){
  lookupItems(type: $type,tenantID: $tenantID){        
    lookupID,
    lookupTypeID,
    name,
    value,
    orderIndex       
   }
 }
`;

export const GET_DISTRICTUSERS = gql`
 query($tID: Int!,$pagesize: Int!,$pagenumber: Int!,$userfilter : String!,$isActive : Boolean!){
  districtUsers(tID: $tID,pagesize: $pagesize,pageNumber: $pagenumber,userFilter: $userfilter,isActive : $isActive){        
    recCount,
    pageNumber,
    pageSize,   
    districtUsers{
      districtUserID,
      schoolID,
      isDistrictAdmin,
      isSchoolAdmin,
      isCourseDesigner, 
      userProfileID,
      firstName,
      lastName,
      tenantID,
      username,
      email,           
      token,      
      flag
    }       
   }
 }
`;

export const GET_SCHOOLS_BY_TENANTID = gql`
 query($tenantId: Int!){
  schoolsByTenantId(tenantId: $tenantId){        
    schoolID,
    name,
    description,
    tenantID,
    eLSchoolID         
   }
 }
`;

export const GET_FPLOGINACCOUNT = gql`
 query($userProfileId: Int!){
  fPLoginAccountById(userProfileId: $userProfileId){        
    userProfileID,
    token,
    username,
    isLockedOut,
    isOnline,
    lastActivityDate,
    providerName,
    creationDate,
    email,
    lastLoginDate,
    isApproved       
   }
 }
`;

export const GET_DISCUSSION_ACTIVITY = gql`
 query($activityId: Int!,$mode: String!,$id: Int!){
  discussionActivityDetails(activityId: $activityId,mode: $mode,id: $id){        
      userProfileID,
      firstName,
      lastName,
      userName,
      email,
      tenantID,
      schoolID,
      isDistrictAdmin,
      isSchoolAdmin,
      isCourseDesigner,
      studentID,
      districtUserID,
      teacherID     
   }
 }
`;

export const GET_YEARS = gql`
query{
  allYears{
    yearId,
    value
  }
}
`;

export const GET_ZONES = gql`
query{
  allTimeZones{
   timeZoneID,
   name,
   utcoffset,
   location,
   code
  }
}
`;

export const GET_STATES = gql`
  query{
    allStates{
    stateId,
    name
  }
}
`;

export const GET_THEMES = gql`
  query{
    allThemes{
      themeID,
      name,
      code
    }
}
`;

export const GET_WEIGHTAGE_CATEGORIES = gql`
  query($tenantId: Int!){
    allWeightageCategories(tenantId: $tenantId){
      weightageCategoryId,
      name,
      weightage,
      tenantId
    }
}
`;

export const GET_LTI_CONTENT_PROVIDERS = gql`
 query($tenantId: Int!){
  ltiContentProviders(tenantId: $tenantId){
    name,
    description,
    key,
    secret,
    customParameters,
    ltiProviderID,
    ltiContentProviderID
    }
}
`;

export const ADD_LTI_CONTENT_PROVIDERS = gql`
  mutation($ltiModel:LtiContentProvidersModelInput!){
    saveLtiContentProvider(ltiContentProvider:$ltiModel){
      message,
      id,
      goalID,
      status
    }
  }
`
export const ADD_TENANT = gql`
mutation($tenant:TenantModelInput!,$scoreSearchType:String!,$zoomApiKey:String!,$zoomApiSecret:String!,$isEdisonRedirect:Boolean!){
  addTenant(tenant: $tenant,scoreSearchType:$scoreSearchType,zoomApiKey:$zoomApiKey,zoomApiSecret:$zoomApiSecret,isEdisonRedirect:$isEdisonRedirect) {
    tenantID,
    isMaine,
    isSchoology,
    activeSchoolYear
  }
}
`
export const SAVE_WEIGHTAGE_CATEGORY = gql`
mutation($tenantId:Int!,$weightageCategories:[WeightageCategoryModelInput!]!){
  saveWeightageCategoriesList(tenantID: $tenantId, weightageCategories: $weightageCategories) {
    returnFlag,
    returnMessage
  }
}
`

export const ADD_USER = gql`
mutation($userModelInput:UserModelInput!){
  addUser(userModel: $userModelInput) {
    userProfileID,
    tenantID,
    studentID,
    districtUserID,
    teacherID
  }
}
`;


export const SAVE_COURSESECTION = gql`
mutation($courseSectionModelInput:CourseSectionModelInput!,$userProfileId:Int!,$isRemove:Boolean!){
  saveCourseSection(courseSectionModelInput: $courseSectionModelInput,userProfileId: $userProfileId,isRemove:$isRemove) {
    courseSectionID,
    yearLID,
    name,
    description,
    isActive,
    tenantID,
    examPercentage,
    testPercentage,
    quizPercentage,
    assignmentPercentage,
    discussionPercentage,
    participationPercentage,
    sourceID,
    identifier    
  }
}
`;
export const SAVE_CLASSROOM = gql`
mutation($classroomModelInput:ClassroomModelInput!){
  saveClassroom(classroomModelInput: $classroomModelInput) {
    classroomID,
    name,
    description,
    schoolID    
  }
}
`;

export const SAVE_SCHOOL = gql`
mutation($schoolModelInput:SchoolModelInput!){
  saveSchool(schoolModelInput: $schoolModelInput) {
    schoolID,
    name,
    description,
    tenantID,   
    eLSchoolID 
  }
}
`;

export const SAVE_IMPERSONATE_USER = gql`
mutation($userId:Int!,$impersonateId:Int!,$isremove:Boolean!){
  saveImpersonateUser(userId: $userId,impersonateId: $impersonateId,isremove: $isremove) {
    schoolID,
    name,
    description,
    tenantID,   
    eLSchoolID 
  }
}
`;


export const RESET_PASSWORD = gql`
mutation($userProfileId:Int!){
  addResetPassword(userProfileID: $userProfileId) {
    message,
      id,
      goalID,
      status,
      saved
  }
}
`;

export const ADD_CREATE_FPLOGINACCOUNT = gql`
mutation($userProfileId:Int!){
  addCreateFPLoginAccount(userProfileID: $userProfileId) {
    userProfileID,
    token,
    username,
    isLockedOut,
    isOnline,
    lastActivityDate,
    providerName,
    creationDate,
    email,
    lastLoginDate,
    isApproved
  }
}
`;

export const DELETE_FPLOGINACCOUNT = gql`
mutation($userProfileId:Int!){
  addDeleteFPLoginAccount(userProfileID: $userProfileId) {
    userProfileID,
    token,
    username,
    isLockedOut,
    isOnline,
    lastActivityDate,
    providerName,
    creationDate,
    email,
    lastLoginDate,
    isApproved
  }
}
`;
export const GET_TENANT_ACTIVITYTYPES = gql`

query($role: String!, $tenantID: Int!)
{
  tenantActivityTypes(where: {and: [ { tenantID : {eq:$tenantID} },{activityType:{roles:{ contains: $role }}} ]})
  {
  tenantID,
  activityType {
    activityTypeID
    name,
    description,
    code,
    isSelfLearning ,
    player,
    administer ,
    editor,
    isVirtualLearning,
    report,
    clientEditController,
    clientPlayerController
  }
}
}
`

export const GET_GOALINSTANCE_BY_ID = gql`

query( $Id: Int!)
{

goalInstanceById(where: {and: [ { goalInstanceID : {eq:$Id} } ]})
 {
  
 goalInstanceID,
       name,
        goalTypeID,
        orderIndex,
        ownerID,
        createdBy,
        createdOn,
        description,
          isPublished,
          imageUploadID,
          paymentAmount,
          subscriptionType,
          marketingContent,
          lTAssessmentWgt,
          lTAssignmentWgt,
          lTDiscussionWgt
          lTExternalToolWgt,
          lTPointfulWgt
          goalType {
            goalTypeID,
            name,
            code
          },
       
     
       parentGoalInstance {
         name,
         parentGoalInstanceID,
         goalTypeID
       },
       goalInstanceStandards{
        standard {
          standardID,
          code,
          description,
                                   
        }
       },
       goalInstanceTags{
         tagL {
           lookupID,
           name,
           lookupTypeID,
           value,
           lookupType{
             name
           }
         }
       }

       

}
}`
export const Get_Goal = gql`

query( $goalID: Int!, $mode:String!)
{

goal( goalID : $goalID, mode :$mode)
 {
  
        goalID,
        communityID,
        goalTypeID,
        parentGoalID,
        parentGoalName,
        parentGoalTypeID,
        orderIndex,
        ownerID,
        tenantID,
        createdBy,
        createdOn,
        name,
        description,
        isPublished,
        isProviderCourse,
        mode,
        marketingContent,
        paymentAmount, 
        subscriptionType, 
        imageUploadID,
        lTAssessmentWgt,
        lTAssignmentWgt,
        lTDiscussionWgt
        lTExternalToolWgt,
        lTPointfulWgt
          
        standards {
          standardID,
          code,
          description,
          isSelected,
          gradeType,
          gradeID,
          gradeName,
          gradeValue,
          subjectType,
          subjectID,
          subjectName,
          subjectValue 
       },
       tags{
           lookupID,
           name,
           lookupTypeID,
           value,
           isSelected,
           lookupTypeName
       }

       

}
}`

export const ADD_Goal = gql`
mutation($model:GoalModelInput!){
  saveGoal(model: $model) {
    iD,
    status,
    message,
  }
}`

export const PUBLISH_ALL_CHILDGOALS = gql`
mutation($communityID:Int!,$goalID:Int!,$status:Boolean!){
  publishAllChildGoals(communityID:$communityID,goalID:$goalID,status:$status) {
    iD,
    status,
    message,
  }
}`

export const PUBLISH_ALL_CHILDGOALINSTANCES = gql`
mutation($communityID:Int!,$goalID:Int!,$status:Boolean!){
  publishAllChildGoalInstances(communityID:$communityID,goalID:$goalID,status:$status) {
    iD,
    status,
    message,
  }
}`
export const REQUIRED_ALL_CHILD_GOALINSTANCES = gql`
mutation($goalInstanceID:Int!,$status:Boolean!){
  requiredAllChildGoalInstances(goalInstanceID:$goalInstanceID,status:$status) {
    iD,
    status,
    message,
  }
}`
export const LAUNCH_ALL_CHILD_GOALINSTANCES = gql`
mutation($goalInstanceID:Int!,$status:Boolean!){
  launchAllChildGoalInstances(goalInstanceID:$goalInstanceID,status:$status) {
    iD,
    status,
    message,
  }
}`

export const GET_GOAL_TYPES = gql`

query($type:String! , $parentID:Int! , $tenantID:Int! )
{
  goalTypes(type:$type,parentID:$parentID, tenantID:$tenantID)
  {
    goalTypeID,
  name,
  description,
  code
}
}
`
export const SAVE_GOAL_SCHEDULE = gql`
mutation ( $goalInstanceId:Int!,$startDate: DateTime!, $endDate: DateTime!) {
  saveGoalSchedule(goalInstanceId:$goalInstanceId,startDate:$startDate,endDate:$endDate) 
  {
    goalInstanceID
  }
}
`
export const GET_GOAL_FOLDER = gql`
query($goalID:Int! , $mode:String!)
{
  goalInstanceFolders(goalID:$goalID,mode:$mode,)
  {
  goalFolderID,
  folderID,
  name,
  parentFolderID,
  description,
  uploadID

}
}`
export const DELETE_GOAL_SCHEDULE = gql`
mutation ( $goalInstanceID:Int!) {
  deleteGoalInstance(goalInstanceID:$goalInstanceID) 
  {
    iD,
    status,
    message,
  }
}
`
export const UPDATE_BOOKMARK_COMMUNITY = gql`
mutation ( $communityId:Int!,$status:Boolean!) {
  updateBookMarkCommunity(communityId:$communityId,status:$status) 
  {
    iD,
    status,
    message,
  }
}
`



export const SAVE_ASSIGNMENT_ACTIVITY = gql`
mutation($activityInput:AssignmentModel!){
  saveAssignmentActivity(assignmentActivity : $activityInput) {
    returnFlag,
    returnMessage
  }
}
`
export const GET_COMMUNITY_MEMBERS = gql`

query($cID:Int! , $uID:Int!,$type:Int!)
{
  communityMembers(cID:$cID,uID:$uID,type:$type)
  {
     isWaitingForApproval,
        isJoinedWithCommunity ,
        userProfileID ,
        memberName,
       isOwner,
        isObserver,
        isCoOwner ,
        courseGrade,
       courseProgress,
      communities ,
      studentID,
      picUploadID ,
      communityMemberID,
    

}
}
`
export const GET_COMMUNITY_SEARCH_USERS = gql`
query($communityID:Int! , $memberTenantID:Int!,$uID:Int!,$filter:String!,$pageNum:Int!,$pageSize:Int!)
{
  searchUsersWithPaging(communityID:$communityID,memberTenantID:$memberTenantID,uID:$uID,filter:$filter,pageNum:$pageNum,pageSize:$pageSize)
  {

  rOWNUM,
       totalCount,
      userProfileID ,
        firstName,
        lastName ,
        fullName ,
        role
}
}`

export const GET_TENANT_APPS = gql`
query($tenantId: Int!)
    {

   tenantApps(tenantId:$tenantId,where: {and: [ { tenantID : {eq:$tenantId} },{app:{code:{ in: ["Courses","DD","LearningTree"] }}} ]})
   {
   tenantAppID,
   app {
     code
   }
 }

}`
export const GET_TENANT_APP_Modules = gql`

query($tenantId: Int!,$role:String!)
{

tenantAppModulesByRole(tenantId:$tenantId,role:$role,where: {and: [ { tenantID : {eq:$tenantId} },{app:{roles:{ contains: $role }}} ]})
{
tenantAppID,
app {
 code
}
}

}`
export const SET_RESTRICT_STATUS = gql`
mutation($id:Int!,$type:String!,$status:Boolean!){
  setRestrictStatus(id:$id,type:$type,status:$status) {
    iD,
    status,
    message,
  }
}`

export const PUBLISH_ALL_GOALACTIVITIES = gql`
mutation($parentID:Int!,$itemId:Int!,$type:String!,$status:Boolean!){
  publishAllGoalActivities(parentID:$parentID,itemId:$itemId,type:$type,status:$status) {
    iD,
    status,
    message,
  }
}`
export const REQUIRED_ALL_CHILD_GOALACTIVITIES = gql`
mutation($parentID:Int!,$itemId:Int!,$type:String!,$status:Boolean!){
  requiredAllGoalActivities(parentID:$parentID,itemId:$itemId,type:$type,status:$status) {
    iD,
    status,
    message,
  }
}`
export const LAUNCH_ALL_CHILD_GOALACTIVITIES = gql`
mutation($parentID:Int!,$itemId:Int!,$type:String!,$status:Boolean!){
  launchAllGoalActivities(parentID:$parentID,itemId:$itemId,type:$type,status:$status) {
    iD,
    status,
    message,
  }
}`
export const SAVE_GOALINSTANCE_USERCTIVITIES = gql`
mutation($goalInstanceID:Int!,$communityID:Int!,$userID:Int!){
  saveGoalInstanceUserActivities(goalInstanceID:$goalInstanceID,communityID:$communityID,userID:$userID) {
    iD,
    status,
    message,
  }
}`

export const DELETE_USERS_BY_TENANTID = gql`
mutation($userProfileID: Int!){
  deleteUser(userProfileID: $userProfileID)
}
`;

export const MOVE_GOAL_ITEM = gql`
mutation($id:Int!,$itemType:String!,$direction:String!){
  moveGoalItem(id:$id,itemType:$itemType,direction:$direction) {
    iD,
    status,
    message,
  }
}`
export const GET_COMMUNITY_GOALINSTANCE = gql`
query($communityID:Int! , $id:Int!)
{
  communityGoalInstance(communityID:$communityID,id:$id,)
  {
    id,
    name ,
    description ,
    startDate ,
    enddate ,
    isPublished ,
    orderIndex ,
    parentID ,
    typeID ,
    isLaunchable ,
    code ,
    standards ,
    activityCount ,
    itemType ,
    goalTypeName ,
    isMandatory ,
    activities{
      communityID ,
      goalID ,
      activityID ,
      goalInstanceActivityID ,
      activityName ,
      activityDescription ,
      beginning ,
      developing ,
      proficient ,
      distinguished ,
     nA ,
      notStarted ,
      reopen ,
     inProgress, 
      submitted ,
      completed ,
      isPublished ,
      isLaunchable ,
      isMandatory ,
      countRead ,
      countNotread ,
      startDate 
      endDate 
      code 
      orderIndex 
      administer 
      clientAdministerController 
      iD 
      name 
      activityTypeName 
      itemType 
      isOriginal 
      isGraded 
      isAssignment 
      capturReadStatus 
      standards 
      isRestrict 
     } 


}
}`
export const GET_COMMUNITY_GOALINSTANCE_CHILDREN = gql`
query($communityID:Int! , $id:Int!)
{
  communityGoalInstanceChildren(communityID:$communityID,parentID:$id,)
  {
    id,
    name ,
    description ,
    startDate ,
    enddate ,
    isPublished ,
    orderIndex ,
    parentID ,
    typeID ,
    isLaunchable ,
    code ,
    standards ,
    activityCount ,
    itemType ,
    goalTypeName ,
    isMandatory ,
    activities{
      communityID ,
      goalID ,
      activityID ,
      goalInstanceActivityID ,
      activityName ,
      activityDescription ,
      beginning ,
      developing ,
      proficient ,
      distinguished ,
     nA ,
      notStarted ,
      reopen ,
     inProgress, 
      submitted ,
      completed ,
      isPublished ,
      isLaunchable ,
      isMandatory ,
      countRead ,
      countNotread ,
      startDate 
      endDate 
      code 
      orderIndex 
      administer 
      clientAdministerController 
      id 
      name 
      activityTypeName 
      itemType 
      isOriginal 
      isGraded 
      isAssignment 
      capturReadStatus 
      standards 
      isRestrict 
     } 


}
}`
export const MOVE_GOALITEM = gql`
mutation($id:Int!,$itemType:String!,$direction:String!){
  launchAllGoalActivities(id:$id,itemType:$itemType,tydirectionpe:$direction) {
    iD,
    status,
    message,
  }
}`

export const GOAL_ITEMS = gql
  `query($type:String!, $parentID:Int!, $tenantID:Int!, $ownerID:Int!, $filter:String!, $providerId:Int!, $pageNumber:Int!, $pageSize:Int!)
{
  goalItems(type:$type, parentID:$parentID, tenantID:$tenantID, ownerID:$ownerID, filter:$filter, providerId:$providerId, pageNumber:$pageNumber, pageSize:$pageSize)
  {
    totalCount,
    communityID,      
  id,      
  name,      
  description,      
  orderIndex,      
  parentID,      
  typeID,      
  code,      
  standards,    
  childTypesCount,      
  goalTypeName,      
  childTypeName,      
  isPublished,      
  subscriptionType,      
  paymentAmount,       
  marketingContent,       
  imageUploadID,      
  imageSrc,      
  activityCount,      
  itemType    

}
}`

export const GOAL_ACTIVITIES_BY_GOAL = gql
  `query( $id: Int!)
{

goalActivitiesByGoal( id : $id)
 {
  
       id,
       capturReadStatus,
       name,
       activityTypeName,
       description,
       orderIndex,
       parentID,
       typeID,
       code,
       standards,
       childTypesCount,
       isPublished,
       beginningActivityID,
       developingActivityID,
       proficientActivityID,
       distinguishedActivityID,
       locationTop,
       locationLeft,
       goalActivityID,
       toOrder,
       itemType,
       toConnectors,
       isRestrict,
      
}
}`

export const SAVE_SELFSUBSCRIBE_COURSE = gql`
mutation ( $communityID:Int!, $goalInstanceID:Int!, $userProfileID: Int!, $userFullName:String!,$memberName:String!,  $isSelfSubscribed:Boolean!, $isMemberActive:Boolean!,$isActive:Boolean!,$isGoalarchive:Boolean!) {
  selfSubscribeCourse(communityID:$communityID,goalInstanceID:$goalInstanceID,userProfileID:$userProfileID,userFullName:$userFullName,memberName:$memberName,isSelfSubscribed:$isSelfSubscribed, isMemberActive:$isMemberActive,isActive:$isActive,isGoalarchive:$isGoalarchive) 
  {
    iD,
    status,
    message,
  }
}
`

export const CREATE_GOAL_INSTANCE = gql`
mutation ( $communityModel:CommunityModelInput!, $goalID:Int!) {
  createGoalInstance(communityModel:$communityModel,goalID:$goalID) 
  {
    iD,
    status,
    message,
  }
}
`

export const SAVE_DISCUSSION_ACTIVITY = gql`
mutation($discussionModelInput:DiscussionModelInput!){
  saveDiscussionActivity(discussionModel : $discussionModelInput) {
    returnFlag,
    returnMessage
  }
}
`
export const ADD_COMMUNITY_MEMBER = gql`
mutation($communityID:Int!,$uID:Int!,$by:String!,$memberName:String!,$isActive:Boolean!){
  addCommunityMember(communityID:$communityID,uID:$uID,by:$by,memberName:$memberName,isActive:$isActive) {
    iD,
    status,
    message,
  }
}`;
export const UPDATE_COMMUNITY_MEMBER_STATUS = gql`
mutation($communityID:Int!,$uID:Int!,$isCoOwner:Boolean!,$isObserver:Boolean!){
  updateCommunityMemberStatus(communityID:$communityID,uID:$uID,isCoOwner:$isCoOwner,isObserver:$isObserver) {
    iD,
    status,
    message,
  }
}`;
export const GET_SUBSCRIBED_GOALINSTANCES = gql`
query($communityID: Int!,$userId:Int!)
    {

   subscribedGoalInstances(communityID:$communityID,userId:$userId)
   {
    communityGoalInstanceMemberID,
    goalInstanceID ,
    description ,
    parentGoalInstanceID ,
    name ,
    subscribeStatus,
    isGoalarchive,
    isPacingCommunity,
    isSelfSubscribed
 }

}`
export const UPDATE_COMMUNITY_GOALINSTANCE_MEMBER = gql`
mutation($member:CommunityGoalInstanceMemberModelInput!){
  updateCommunityGoalInstanceMember(member:$member){
    message,
    iD,
    status
  }
}`

export const GET_GOALINSTANCE_ACTIVITIES_LEARNER = gql`
query($communityID: Int!,$userProfileID:Int!,$goalID:Int!)
    {

      communityGoalInstanceActivitiesForLearner(communityID:$communityID,userProfileID:$userProfileID,goalID:$goalID)
   {
     communityID 
     goalID 
     activityID 
     goalInstanceActivityID 
     activityName 
     activityDescription 
     beginning 
     developing 
     proficient 
     distinguished 
     nA 
     notStarted 
     reopen 
     inProgress 
     submitted 
     completed 
     isPublished 
     isLaunchable 
     isMandatory 
     countRead 
     countNotread 
     startDate 
     endDate 
     code 
     orderIndex 
     administer 
     clientAdministerController 
     id 
     name 
     activityTypeName 
     itemType 
     isOriginal 
     isGraded 
     isAssignment 
     capturReadStatus 
     standards 
     isRestrict 
 }

}`;
export const SAVE_QUESTION_TAGS = gql`
mutation($model:InteractionModelInput!,$tenantId:Int!){
  saveQuestionTags(model : $model,tenantId:$tenantId) {
     status,
     message,
     id,
     goalID,
     saved
  }
}`;

export const GET_USER_EXTERNALTOOL_Activity = gql`
query($activityID: Int!,$userProfileID:Int!,$giActivityID:Int!)
{

userExternalToolActivity(activityID:$activityID,userProfileID:$userProfileID,giActivityID:$giActivityID)
{
  ltiToolProviderID
  url
  toolName
  customParameters
  consumerKey
  consumerSecret
  isCustomParameterEditable
  trainingID
  trainingAssigneeID
  ltiContentProviderID
  ltiContentProviderName
 communityID 
 goalID 
 activityID 
 goalInstanceActivityID 
 beginning 
 developing 
 proficient 
 distinguished 
 code 
 orderIndex 
 name 
 activityTypeName 
 isGraded 
 isAssignment,
 hasFeed,
 hasAttachments,
 standards{
  standardID,
}
tags{
  lookupTypeID
},
rubrics{
  rubric
},
 userContext {
  status
  userFullName
  userActivityID,
 userProfileID,
 activityID,
  teacherID,
 score,
 maxScore,
  token,
  testToken,
 isMarkAsRead,
 isCredlyBadgeClaimed,
 goalInstanceActivityID,
 studentNotes,
 excused,
 historyCount
} 
}

}`

export const GET_USER_ASSIGNMENT_ACTIVITY=gql`
query($activityID: Int!,$userProfileID:Int!,$giActivityID:Int!)
{

userAssignmentActivity(activityID:$activityID,userProfileID:$userProfileID,giActivityID:$giActivityID)
{
 communityID 
 goalID 
 activityID 
 goalInstanceActivityID 
 beginning 
 developing 
 proficient 
 distinguished 
 code 
 orderIndex 
 name 
 description
 activityTypeID
 activityTypeName 
 isGraded 
 isAssignment 
 hasFeed
 hasAttachments
 textEntry
 ownerID
 weightage
 badgeID
 tenantID
 standards {
  standardID
}
tags {
  lookupID,
  lookupTypeID,
  name
}
rubrics {
  rubric
}
 userContext {
  userActivityID,
 userProfileID,
 activityID,
  teacherID,
 testToken,
 status,
 score,
 maxScore,
  token,
 isMarkAsRead,
 isCredlyBadgeClaimed,
 goalInstanceActivityID,
 studentNotes,
 excused,
 historyCount
}
}
}`

export const GET_LTI_LAUNCH =gql`
query($id: Int!,$userProfileID:Int!,$studentId:Int!,$communityID:Int!)
    {

    ltiLaunch(id:$id,userProfileID:$userProfileID,studentId:$studentId,communityID:$communityID)
   {
    action,
    fields{
      name,
      value
    },
    signature
   
 }

}`


export const GET_ACTIVITY_FOLDERS = gql`
query($id: Int!)
    {
      activityFolders(id:$id)
   {
     activityFolderID ,
        folderID,
        name ,
        parentFolderID,
        description,
       uploadID
   }

}
`

export const GET_USER_ACTIVITY_FOLDERS =gql`
query($userGoalInstanceActivityID: Int!,$folderType:String!)
{
userActivityFolders(userGoalInstanceActivityID:$userGoalInstanceActivityID, folderType:$folderType)
{
  userActivityFolderID,
  folderID,
  name,
  parentFolderID,
  description,
  uploadID
}
}
`

export const SAVE_NOTES_ENTRY = gql`
mutation($model: AssignmentModelInput!)
    {
      saveNotesEntry(model:$model)
   {
    message,
    iD,
    status
   }

}
`
export const SAVE_USER_ACTIVITY_FOLDER =gql`
mutation($userGoalInstanceActivityID: Int!,$folderID:Int!,$name: String!,$desc:String!,$oID: Int!,$parentFolderID:Int!)
{
saveUserActivityFolder(userGoalInstanceActivityID:$userGoalInstanceActivityID, folderID:$folderID,name:$name,desc:$desc,oID:$oID,parentFolderID:$parentFolderID)
{
  iD,
  status,
  message
}
}`

export const SAVE_TEXTENTRY =gql`
mutation($model: AssignmentModelInput!, $userProfileId: Int!, $communityID: Int!)
    {
    saveTextEntry(model:$model,userProfileId:$userProfileId, communityID: $communityID)
   {
    message,
    iD,
    status
   }

}
`
export const SUBMIT_ASSIGNMENT =gql`
mutation($model: AssignmentModelInput!, $userProfileId: Int!)
    {
      submitAssignment(model:$model,userProfileId:$userProfileId)
   {
    message,
    iD,
    status
   }

}
`
export const RESET_LEARNER_ACTIVITY =gql`
mutation($userProfileID:Int!, $activityID:Int!, $communityID:Int!, $mode:String!, $giActivityID:Int!)
    {
      resetLearnerActivity(userProfileID:$userProfileID,activityID:$activityID,communityID:$communityID,mode:$mode,giActivityID:$giActivityID)
   {
    returnFlag,
    returnMessage
   }

}
`
export const RESCORE_LEARNER_ACTIVITY =gql`
mutation($activityID:Int!,$communityID:Int!, $userProfileID:Int!,  $giActivityID:Int!)
    {
      rescoreLearnerActivity  (activityID:$activityID, communityID:$communityID, userProfileID:$userProfileID, giActivityID:$giActivityID)
   {
    returnFlag,
    returnMessage
   }

}
`
export const REOPEN_LEARNER_ACTIVITY =gql`
mutation($activityID:Int!,$communityID:Int!, $userProfileID:Int!,  $giActivityID:Int!, $activityName:String!)
    {
      reopenLearnerActivity  (activityID:$activityID, communityID:$communityID, userProfileID:$userProfileID, giActivityID:$giActivityID,activityName:$activityName)
   {
    returnFlag,
    returnMessage
   }

}`

export const RECORD_ACTIVITY_ENDED =gql`
mutation($userProfileId: Int!, $sessionID: String!, $userGoalInstanceActivityID: Int!,$goalInstanceActivityID:Int!)
    {
    recordActivityEnded(userProfileId:$userProfileId, sessionID:$sessionID, userGoalInstanceActivityID:$userGoalInstanceActivityID, goalInstanceActivityID:$goalInstanceActivityID)
    {
      message,
      iD,
      status
    }
}
`

export const SUBMIT_STUDENT_ACTIVITY =gql`
mutation($userProfileId: Int!,$goalInstanceActivityId:Int!,$userGoalInstanceActivityId:Int!)
{
  SubmitStudentActivity(userProfileId:$userProfileId,goalInstanceActivityId:$goalInstanceActivityId,userGoalInstanceActivityId:$userGoalInstanceActivityId)
   {
    message,
    iD,
    status
   }

}
`


export const GET_USER_ASSESSMENT_ACTIVITY = gql`
query($activityID: Int!,$userprofileID:Int!,$giActivityID:Int!,$goalId:Int)
{

  userAssessmentActivity(activityID:$activityID,userprofileID:$userprofileID,giActivityID:$giActivityID,goalId:$goalId)
{
 communityID ,
 goalID ,
 activityID ,
 goalInstanceActivityID ,
 beginning ,
 developing ,
 proficient ,
 distinguished ,
 code ,
 orderIndex, 
 name ,
 activityTypeName ,
 isGraded ,
 isAssignment ,
 userContext {
  
  userActivityID,
 userProfileID,
 activityID,
  teacherID,
 testToken,
 status,
 score,
 maxScore,
  token,
 isMarkAsRead,
 isCredlyBadgeClaimed,
 goalInstanceActivityID,
 studentNotes,
 excused,
 historyCount
}
}
}`

export const GET_USER_CONTEXT = gql`
query($userActivityID: Int!,$maxScore:Decimal!)
{

userContext(userActivityID:$userActivityID,maxScore:$maxScore)
{
 userActivityID,
 userProfileID,
 userFullName,
 activityID,
  teacherID,
 testToken,
 status,
 score,
 maxScore,
  token,
 isMarkAsRead,
 isCredlyBadgeClaimed,
 goalInstanceActivityID,
 studentNotes,
 excused,
 historyCount
}
}`

export const GOALINSTANCE_ACTIVITY_USERS = gql
`query($goalInstanceActivityID: Int!,$activityID:Int!,$communityID:Int!,$userProfileID:Int!)
{

goalInstanceActivityUsers(goalInstanceActivityID:$goalInstanceActivityID,activityID:$activityID,communityID:$communityID,userProfileID:$userProfileID)
{
 communityID 
 communityTypeID 
 communityType
communityName
goalInstanceID
goalName
 activityID 
 activityName
 description
 beginning 
 developing 
 proficient 
 distinguished 
 orderIndex
 weightage 
 distinguishedText
 proficientText
 developingText
  beginnerText
 bdgId 
 capturReadStatus
 player
 clientPlayerController 
 report
 administer
 clientAdministerController
 code
 activityType
 goalType
 readAloud
 startDate
 endDate
 isPublished
 goalInstanceActivityID
 instanceActivityOrderIndex
 instanceOrder
 instanceOrderIndex
 parentGoalInstanceId
 isGraded 
 weighatgeCategoryID
isPrintBubblesheetActivity
userProfileID
communityMemberID
memberName
showEpubToggle
token
testToken
userActivityID
performance
scoring
score
maxScore
isBookmarked
excused
studentSubmitDate
isMarkAsRead
status
displayStatus
badgeId
credlyClaimID
isCredlyBadgeClaimed
credlyClaimCode
createdOn
badgeUrl
isScorableActivity
historyCount
}
}`
export const GET_USER_ACTIVITY_MESSAGES = gql`
query($id: Int!)
{

userActivityMessages(id:$id)
{
  messageID
  content
  importance
  messagedByID
  messagedOn
  modifiedBy
  modifiedOn
  parentMessageID
  messagedBy
  userProfileID
  isActive
}
}`
export const SAVE_USER_ACTIVITY_MESSAGE = gql`
mutation($model: DiscussionMessageInput!)
{

userActivityMessages(model:$model)
{
  messageID
  content
  importance
  messagedByID
  messagedOn
  modifiedBy
  modifiedOn
  parentMessageID
  messagedBy
  userProfileID
  isActive
}
}`
export const DELETE_MESSAGE = gql`
mutation($messageId:Int!){
  deleteCommunityMessage(messageId:$messageId){
    returnFlag,
    returnMessage
  }
}`
export const SAVE_COMMUNITY_STATE = gql`
mutation($state:String!,$communityID:Int!){
  saveCommunityState(state:$state,communityID:$communityID) {
    iD,
    status,
    message,
  }
}`
export const SAVE_HEXAGON_STATE = gql`
mutation($positionLeft:String!,$positionTop:String!,$color:String!,$id:Int!,$type:String!){
  saveHexagonState(positionLeft:$positionLeft,positionTop:$positionTop,color:$color, ,id:$id,type:$type) {
    iD,
    status,
    message,
  }
}`
export const SAVE_HONEYCOMB_STATE = gql`
mutation($state:String!,$type:String!){
  saveHoneycombState(state:$state,type:$type) {
    iD,
    status,
    message,
  }
}`
export const DELETE_TEST =gql`
mutation($testID:Int!,$tenantID:Int!){
  deleteTest(testID:$testID,tenantID:$tenantID){
    iD,
    status,
    message
  }
}
`
export const CLONE_TEST=gql`
mutation($testId:Int!,$testName:String!,$userName:String!,$tenantID:Int!){
  cloneTest(testId:$testId,testName:$testName,userName:$userName,tenantID:$tenantID)

 }
`
export const SUBMIT_EXTERNALTOOL =gql`
mutation($model: ExternalToolModelInput!, $userProfileID: Int!)
    {
      submitExternalTool(model:$model,userProfileID:$userProfileID)
   {
    message,
    iD,
    status
   }

}
`
export const GET_DOK_QUESTIONS = gql`
query($userprofileID:Int!,$goalInstanceID:Int!,$contentProgressionId:Int!)
{

  learningObject(userprofileID:$userprofileID,goalInstanceID:$goalInstanceID,contentProgressionId:$contentProgressionId)
{
  questionPackageId,
  questionID,
  dOKID,
  activityType,
  showStatus,
  userDokPercentage,
  name,
  questionContent,
  userGoalInstanceProgressionID
  activityID
  progressionStatus,
  dOKPercentage,
  goalInstanceActivityID,
  goalName
}
}`
export const SAVE_DOK_PROGRESSION = gql`
mutation ( $userGoalInstanceProgression:UserGoalInstanceProgressionModelInput!) {
  saveUserGoalInstanceProgression(userGoalInstanceProgression:$userGoalInstanceProgression) 
  {
    userGoalInstanceProgressionID
  }
}
`
export const CLONE_QUESTION = gql`
mutation($questionID:Int!,$userProfileID:Int!,$userName:String!){
  cloneQuestion(questionID:$questionID,userProfileID:$userProfileID,userName:$userName)
}
`
export const GET_USER_PROGRESSION = gql`
query($prID:Int!)
{
  userProgression(prID:$prID)
{
  userGoalInstanceProgressionID,
  userProfileID,
  goalInstanceID,
  progressionType,
  state,
  status,
  score,
  maxScore
}
}`
export const GET_ALL_LTI_CONTENT_PROVIDERS = gql`
query
{
  allLTIContentProviders
{
  lTIContentProviderID,
  name,
  description
}
}`
export const Get_LTI_PROVIDER_DOMAINS = gql`
query($providerId:Int!,$tenantId:Int!)
{
 ltiProviderDomains(providerId:$providerId,tenantId:$tenantId)
{
  tenantLtiProviderID
  tenantID 
  ltiContentProviderID 
  domainName
  consumerKey
  consumerSecret
  isActive
}
}`
export const SAVE_LTI_PROVIDER_DOMAIN = gql`
mutation ( $tenantLtiProviderModel:TenantLtiProviderModelInput!) {
  saveLtiProviderDomain(tenantLtiProviderModel:$tenantLtiProviderModel) 
  {
    tenantLtiProviderID
  }
}
`