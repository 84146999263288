import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/core/services/common/shared.service';
import { AuthService } from 'src/app/core/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})


export class VerticalAppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};


  // This is for Notifications
  notifications: Notification[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Message[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    }
  ];

  selectedLanguage:any;
  languages:any;
  


  constructor(private translate: TranslateService,private sharedService:SharedService, private authService: AuthService) {
    this.selectedLanguage= sharedService.getGlobalLang();
     this.languages=sharedService.languagesList;
     this.translate.use("en");
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  // signOut(){
  //   this.authService.logoff();
  // }

  
  Logout(){
    this.authService.logoff();
    localStorage.clear();
  }
}
interface Notification{
  round: string,
      icon: string,
      title: string,
      subject: string,
      time: string
}
interface Message{
  useravatar: string,
  status: string,
  from: string,
  subject: string,
  time: string
}