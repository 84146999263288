<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->


<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected"
        group="{{menuitem.state}}" (click)="scrollToTop()">
        <a class="" appAccordionToggle href="javascript:;" 
            *ngIf="menuitem.type === 'sub'  && isValidModule(menuitem.code)">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'" >
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index;" routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]" *ngIf="childitem.type === 'link'&& isValidModule(childitem.code)"
                    class="relative" routerLinkActive="selected">{{ childitem.name | translate}}</a>
            </mat-list-item>
        </mat-nav-list>


    </mat-list-item>
</mat-nav-list>