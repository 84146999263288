<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative" id="appHeader">
    <div class="w-100 fix-width-for-horizontal">
      <!-- ============================================================== -->
      <!-- Logo - style you can find in header.scss -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/">
            <img alt="FocalPointK12" style="width:24px;height:24px;border:none;margin-bottom:8px;margin-right:5px" src="assets/images/icon/FocalPoint-Icon-1.png">
            <span style="font-size: 25px;color: white;">FocalPoint</span>
          </a>
        
      </div>
      <!-- ============================================================== -->
      <!-- sidebar toggle -->
      <!-- ============================================================== -->

      <button mat-icon-button (click)="clickEvent()" class="sidebar-toggle" value="sidebarclosed">
        <mat-icon>menu</mat-icon>
      </button>
      <!-- ============================================================== -->
      <!-- Search - style you can find in header.scss -->
      <!-- ============================================================== -->
      <!-- <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
        <mat-icon>search</mat-icon>
      </button> -->
      <!-- <form class="app-search" [ngClass]="{'show-search': showSearch}">
        <input type="text" class="form-control" placeholder="Search &amp; enter">
        <a class="cl-srh-btn" (click)="showSearch = !showSearch">
          <i class="ti-close"></i>
        </a>
      </form> -->
      <span fxFlex></span>
  
      <app-header></app-header>
      

      <!-- ============================================================== -->
      <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
      <!-- ============================================================== -->
      <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
        <mat-icon>settings</mat-icon>
      </button>
    </div>

  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="isSideNavOpen" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false">
<!-- 
      <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">
        <app-vertical-sidebar class="app-sidebar"></app-vertical-sidebar>
      </perfect-scrollbar>

      <ng-template #horizontalsidebar>
        <app-horizontal-sidebar></app-horizontal-sidebar>
      </ng-template> -->
      <div style="text-align: center;"  *ngIf="!horizontal && !minisidebar" >
        <!-- User profile image -->
        <div class="profile-img">
            <img src="assets/images/users/profile.png" alt="user" style="width:100px"> </div>
        <!-- User profile text-->
        <!-- ============================================================== -->
        <!-- Profile - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="profile-text">
            <a [matMenuTriggerFor]="sdprofile" class=""> {{ userprofile.UserFullName }}
                <i class="ti-angle-down font-12 m-l-5"></i>
            </a>
        </div>
        <mat-menu #sdprofile="matMenu" class="mymegamenu">
            <button mat-menu-item>
                <mat-icon>settings</mat-icon> Settings
            </button>
            <button mat-menu-item>
                <mat-icon>account_box</mat-icon> Profile
            </button>
            <button mat-menu-item>
                <mat-icon>notifications_off</mat-icon> Disable notifications
            </button>
            <button mat-menu-item>
                <mat-icon>exit_to_app</mat-icon> Sign Out
            </button>
        </mat-menu>
    </div>
      <app-sidebar class="app-sidebar"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>

          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item *ngIf="!minisidebar">
            <mat-slide-toggle color="warn" [(ngModel)]="horizontal">
              Horizontal Layout</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item *ngIf="!horizontal">
            <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
          </mat-list-item>
          <mat-divider></mat-divider>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
              Green</mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''" fxLayout="column">
      <app-breadcrumb></app-breadcrumb>
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>

      </div>
      <app-footer fxFlexOffset="auto" style="margin-top: auto;"></app-footer>
    </mat-sidenav-content>
    
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>