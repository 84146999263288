import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthorizationGuard } from './core/authguard';
import { TokenComponent } from './modules/home/token/token.component';
import { TestsEditorComponent } from './modules/qtieditor/tests/tests-editor/tests-editor.component';


export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate:[AuthorizationGuard],
        children: [
            
            // {
            //     path: '',
            //     redirectTo: 'home/dashboard',
            //     pathMatch: 'full'
            // },
            {
                path: 'lt',
                loadChildren: () => import('./modules/lt/lt.module').then(m => m.LtModule)
            },
            {
                path:'admin',
                loadChildren:()=>import('./modules/admin/admin.module').then(m=>m.AdminModule)

            },
            {
                path:'home',
                loadChildren:()=>import('./modules/home/home.module').then(m=>m.HomeModule)

            },
            {
                path:'qti',
                loadChildren:()=>import('./modules/qtieditor/qtieditor.module').then(m=>m.QtieditorModule)

            },
            {
                path:'activity',
                loadChildren:()=>import('./modules/activity/activity.module').then(m=>m.ActivityModule)
            }
            ,
            {
                path:'lp',
                loadChildren:()=>import('./modules/lp/lp.module').then(m=>m.LpModule)

            } 
           
            
        ]
    },
    {
        path: '**',
        redirectTo: 'home/dashboard'
    },
    {
        path:'token',
        component:TokenComponent,
        data: {
          title: 'Token',
          urls: [
              { title: 'Token' }
          ]
        }
      }
      
];
