import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable,BehaviorSubject, Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class AuthService extends BehaviorSubject<any> { 
  public currentUser:Observable<any>;
  public getLoggedInName = new Subject();
  private currentUserSubject = new BehaviorSubject<boolean>(false);
  constructor(public oidcSecurityService: OidcSecurityService) { 
    super("");
    this.currentUser=this.currentUserSubject.asObservable();
  }
  public getToken(): any {
    return this.oidcSecurityService.getAccessToken();
  }
  public loginUserinfo(){
    this.currentUserSubject.next(true);
  }
  public userinfo(){
    
    this.getLoggedInName.next(this.oidcSecurityService.getUserData().name);
    this.getLoggedInName.complete();
    
     return this.oidcSecurityService.getUserData();
  }
  public login(){
    this.oidcSecurityService.authorize();
  }
  public logoff(){
    this.oidcSecurityService.logoff();
  }
}
