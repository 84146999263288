import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './core/services/common/shared.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from './core/services';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators'
import { ApiService } from './core/services'
import { ApiDictionary } from './core/services/common/api-dictionary'
import { LMSApiService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { AppsettingsService } from './core/services/appsettings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  selectedLanguage:any;
  languages:any;
  userClaimsInfo:any;
  utilitySettings : any;

  private subscription: Subscription = new Subscription;

  constructor(public oidcService:OidcSecurityService,private apiService: ApiService, 
    private authService:AuthService, public translate: 
    TranslateService,private sharedservice:SharedService,
    private lmsApiService: LMSApiService, private router: Router, private appsettings : AppsettingsService) {
    this.selectedLanguage=this.sharedservice.selectedLang;
    this.languages=this.sharedservice.languagesList;
  }

  ngOnInit(): void {
    
    // this.oidcService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
   
    //   console.log('isAuthenticated : ' + isAuthenticated);
    //   if(isAuthenticated){
    //     console.log('userData :' + JSON.stringify(this.oidcService.getUserData(), null, 4));
    //     this.userClaimsInfo=this.authService.userinfo();
    //     //this.lmsApiService.setUserProfile(this.userClaimsInfo.sub, "FocalPointK12").subscribe((data: any) => {
    //       this.lmsApiService.setUserProfile(this.userClaimsInfo.name, "FocalPointK12").subscribe((data: any) => {
    //       this.utilitySett`ings = data;
    //       if (this.utilitySettings.token == null){
    //         this.router.navigate(['token']);
    //       }
    //       else
    //       {
    //         this.appsettings.setUserProfile(this.utilitySettings);
    //       //  localStorage.setItem('_loginUsere', JSON.stringify(this.utilitySettings));
    //       }
    //     });
    //   }
    //   else{
    //     this.authService.login();
    //   }

    // });
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  getAllExamLogics(): Observable<any> {
    return this.apiService.get(ApiDictionary.getAllExamPrepLogic.url)
    .pipe(map(data => data));
  }
}
