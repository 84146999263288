import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GET_TENANT_APPS, GET_TENANT_APP_Modules } from 'src/app/generated/queries';
import { AppsettingsService } from '../appsettings.service';
import { AuthService } from '../auth/auth.service';
import { ApiService } from './api.service';
import { LMSApiService } from './nswag-api.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AppinitService implements OnInit {
  userClaimsInfo: any;
  utilitySettings: any;
  constructor(public oidcService: OidcSecurityService, private apiService: ApiService,
    private authService: AuthService, private sharedservice: SharedService,
    private lmsApiService: LMSApiService, private router: Router, private appsettings: AppsettingsService) { }
  ngOnInit(): void {

  }
  getTenantApps() {
    return new Promise((resolve, reject) => {
      this.apiService.postQuery(GET_TENANT_APPS,
        {
          tenantId: this.utilitySettings.tenantID
        }

      ).subscribe(({ data }) => {
        // console.log(this.userProfile)
        this.appsettings.tenantApps = data.tenantApps;
        //resolve(true)
        return resolve(this.getTenantAppModules());
      });
    })
  }
  getTenantAppModules() {
    return new Promise((resolve, reject) => {
      this.apiService.postQuery(GET_TENANT_APP_Modules,
        {
          tenantId: this.utilitySettings.tenantID,
          role: this.utilitySettings.userRole

        }

      ).subscribe((result: any) => {
        this.appsettings.tenantAppModules = result.data.tenantAppModulesByRole;
        return resolve(true);
      });

    })
  }

  verifyUserAuthentication() {
    debugger;
    return new Promise((resolve, reject) => {
      this.oidcService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {

        console.log('isAuthenticated : ' + isAuthenticated);
        if (isAuthenticated) {
          console.log('userData :' + JSON.stringify(this.oidcService.getUserData(), null, 4));
          this.userClaimsInfo = this.authService.userinfo();
          //this.lmsApiService.setUserProfile(this.userClaimsInfo.sub, "FocalPointK12").subscribe((data: any) => {
          this.lmsApiService.setUserProfile(this.userClaimsInfo.name, "FocalPointK12").subscribe((data: any) => {

            this.utilitySettings = data;
            // resolve(this.getTenantApps());
            if (this.utilitySettings.token == null) {
              this.router.navigate(['token']);

            }
            else {
              this.appsettings.setUserProfile(this.utilitySettings);
              this.appsettings.isAdminRequest()
              console.log(this.router.url)
              //if (this.router.url=='/') {
                // if (window.location.href.indexOf('/admin/tenant') <=0) {
                  if (window.location.pathname=='/') {
                  if (this.utilitySettings.studentID > 0)
                    this.router.navigate(['lp/subscriptions']);
                  else
                    this.router.navigate(['lp/courses']);
              }
              else if(!this.appsettings.isValidRequest){
                this.router.navigate(['home/unauthorized']);
              }
              return resolve(this.getTenantApps());
            }

            // this.getTenantAppModules();
            // this.isAppModuleLoaded = true;


          });
        }
        else {
          this.authService.login();
        }
      });

    })
  }

}
