import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of as observableOf, Observable } from 'rxjs';
import { AppsettingsService } from '../services/appsettings.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {

  constructor(private router:Router,private oidcSecurityService: OidcSecurityService, private appsettings: AppsettingsService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (next.data.code) {
      if (this.appsettings.isValidateModule(next.data.code))
        return observableOf(this.oidcSecurityService.isAuthenticated() && this.appsettings.isValidateModule(next.data.code))
        else{
          this.router.navigate(['home/unauthorized']);
          return observableOf(false)
        }
        
    }
   
    else
      return observableOf(this.oidcSecurityService.isAuthenticated());
  }
}