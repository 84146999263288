import { Injectable, OnInit } from '@angular/core';
import { AppsettingsService } from 'src/app/core/services/appsettings.service';
import { SharedService } from 'src/app/core/services/common/shared.service';
import { TenantApp } from 'src/app/generated/graph-ql';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    code?: string;

}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    code?: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}


const MENUITEMS: Menu[] = [
    {
        state: 'lt',
        name: 'Learning',
        type: 'sub',
        icon: 'school',
        code: 'Courses|InterventionPlans|LearningTree|EdisonLearning',
        children: [
            { state: 'administers', name: 'Courses', type: 'link', code: 'LearningTree' },
            { state: 'designs', name: 'Course Designer', type: 'link', code: 'CourseDesigner' },
            { state: 'learns', name: 'Courses', type: 'link', code: 'LearningCourse' },
            
        ]
    },
    {
        state: 'lp',
        name: 'Progression',
        type: 'sub',
        icon: 'local_library',
        code:'Designer|Manage|MySubscriptions',
        children: [
            { state: 'designs', name: 'Designer', type: 'link',code:'Designer' },
            { state: 'courses', name: 'Manage', type: 'link' ,code:'Manage'},
            { state: 'subscriptions', name: 'My Subscriptions', type: 'link' ,code:'MySubscriptions'},
           

        ]
    },

    {
        state: 'qti',
        name: 'QTI Resources',
        type: 'sub',
        icon: 'assignment',
        code:'Assessments|LearningTreeSearch',
        
        children: [
            { state: 'questions', name: 'Questions', type: 'link',code:'Assessments' },
            { state: 'tests', name: 'Tests', type: 'link',code:'Assessments' },
            { state: 'activities', name: 'Activities', type: 'link',code:'LearningTreeSearch' },
        
        ]
    },
    {
        state: 'admin',
        name: 'Administration',
        type: 'sub',
        icon: 'settings',
        code:"AdminCourses|AdminProvisioning|AdminDistrictUsers|AdminFramework|AdminLtiTools|Admin|LearningTreeSearch",
        children: [
            { state: 'provisioning', name: 'SIS Provisioning', type: 'link',code:'AdminProvisioning' },
            { state: 'user', name: 'Users', type: 'link',code:'AdminProvisioning' },
            // { state: 'ltiprovider', name: 'External Apps', type: 'link' ,code:'AdminLtiTools'},
        ]
            
    },

];

@Injectable()
export class MenuItems {
    userprofile: any;
    constructor(private appsettings: AppsettingsService, private sharedservice: SharedService) {
      
    }
   

    getMenuitem(): Menu[] {

        return MENUITEMS;
    }
  

}
