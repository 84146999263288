
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services'
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  couter = 0;

  constructor(private auth: AuthService) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  let authRequest = request;
  authRequest = this.AddTokenHeader(request);

    return next.handle(authRequest).pipe(
      catchError(error => {
         console.log(`error : ${error}`);
          //this.auth.logoff();
         return throwError(error);
      }));
  }

  AddTokenHeader(request:HttpRequest<any>){
    return request.clone({
      setHeaders : {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    })
  }

}