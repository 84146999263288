<div class="token-background">
    <div fxFlex="100" fxLayout="column" >
       
        <mat-card >
            <mat-card-content >
                    <div class="text-center m-b-md">
                        <h3>Token Verification</h3>
                        <small>This is your first time login with this Identity Provider. Please validate login with your token. This is a one time validation.</small>
                    </div>
            </mat-card-content>
        </mat-card>
        <form [formGroup]="tokenForm" fxLayoutAlign="center center">
        <mat-card fxFlex="30">
            <mat-card-content fxLayout="column" class="align-items-center" >
                <div fxFlex>
                    <fp-textbox fxFlex [formName]="tokenForm" controlName="token" placeholder="Token" inputType="text">
                                </fp-textbox>
                </div>
                <div fxFlex>
                    <fp-textbox fxFlex [formName]="tokenForm" controlName="firstName" placeholder="First Name" inputType="text">
                                </fp-textbox>
                </div>
                <div fxFlex>
                    <fp-textbox fxFlex [formName]="tokenForm" controlName="lastName" placeholder="Last Name" inputType="text">
                                </fp-textbox>
                </div>
                <div fxLayout="row" >
                    <button type="button" mat-raised-button  (click)="validate()">Validate</button>
                </div>
            </mat-card-content>
        </mat-card>
        </form>
    </div>
</div>
