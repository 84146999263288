import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Inject, Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { tagTypeModel } from '../../models/facetFilter.model';
import { TagTypeModel } from './nswag-api.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private findQuestionList = new BehaviorSubject('');
  newFindQuestionList = this.findQuestionList.asObservable();
  private questionIDS = new BehaviorSubject('');
  findQuestionListIDS = this.questionIDS.asObservable();
  private tagData = new BehaviorSubject('');
  findTagData = this.tagData.asObservable();
  private questionSort = new BehaviorSubject('');
  newQuestionSort = this.questionSort.asObservable();
  private formData: any;
  private questionFormData: any;
  private passingTestData = new BehaviorSubject('');
  gettingTestData = this.passingTestData.asObservable();
  private questionsTags = new BehaviorSubject<TagTypeModel[]>([]);
  gettingTagData = this.questionsTags.asObservable();
  private testids =new BehaviorSubject('');
  newTestid = this.testids.asObservable();

  testMetaTestTags :TagTypeModel[]=[];

  testMetaTagId :string;
  private allQuestions = new BehaviorSubject('');
  allNewQuestionIDS = this.allQuestions.asObservable();
  
  

  community: any;
  communityId: any;
  rootGoal: any;
  testSectionFormData: any;
  sectionFormData: any;
  Data: any;
  resourceTags: any[] = [];
  resourceLookupTags: any[] = [];
  testMetaTags:tagTypeModel[]=[];
  scoreActivity:any ={};
  adminActivityPlayArray:any [] = [];
  private testSectionData= new BehaviorSubject('');
  sectionsData =this.testSectionData.asObservable();
  widgetPage:PageEvent;
  currentLearningObject :any;


  constructor(private translate: TranslateService) {

  }

allquestionids(ids:any){
  this.allQuestions.next(ids);
}
  getTestsectionData(data:any){
    this.testSectionData.next(data);
  }
  questionOrdering(questionSorting: any) {
    this.questionSort.next(questionSorting);
  }

  public setFormData(formData: any) {
    this.formData = formData;
    // this.questionFormData = questionFormData;
  }

  public getFormData(): any {
    return this.formData;
  }
  updateQuestionList(questionList: any) {
    this.findQuestionList.next(questionList)
  }

  questionListIDS(IDS: any) {
    debugger
    this.questionIDS.next(IDS);
  }
  updateTagData(tagsData: any) {
    this.tagData.next(tagsData);
  }

  setTestData(testData: any) {
    this.passingTestData.next(testData);
  }

  getQuestionTags(tagList: Array<TagTypeModel>) {
    this.questionsTags.next(tagList);
  }


  getTestID(testIDS:any){
    this.testids.next(testIDS);

  }
  public setData(sectionData: any) {
    this.Data = sectionData;
  }

  public getData(): any {
    return this.Data;
  }


 
  parseHierarchicalData = function (data, id, nodeType, idFieldName) {
    var nodeItem = null;
    data.forEach(Forloopfunction);
    function Forloopfunction(item, index, array) {
      if (nodeItem) return;
      if (array[index].itemType == nodeType) {
        if ((array[index][idFieldName] == null ? -1 : array[index][idFieldName]) == id) {
          nodeItem = array[index];
          return;
        } else if (array[index].items && array[index].items.length > 0 && nodeItem == null) { array[index].items.forEach(Forloopfunction); }
      } else if (array[index].items && array[index].items.length > 0 && nodeItem == null) { array[index].items.forEach(Forloopfunction); }
    }
    return nodeItem;
  }

  arraySymmDiffFunc = (function () {
    var contains = function (pred, a, list) {
      var idx = -1, len = list.length;
      while (++idx < len) { if (pred(a, list[idx])) { return true; } }
      return false;
    };
    var complement = function (pred, a, b) {
      return a.filter(function (elem) { return !contains(pred, elem, b); });
    };
    return function (pred) {
      return function (a, b) {
        return complement(pred, a, b).concat(complement(pred, b, a));
      };
    };
  }());

  arrayDiff = this.arraySymmDiffFunc(function (x, y) {
    return x.id === y.id;
  });

  public selectedLang: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languagesList: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',

  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }];

  setGlobalLang(currentLanguage: string) {
    this.translate.setDefaultLang('en');
    this.selectedLang = currentLanguage;
  }

  getGlobalLang(): string {

    return this.selectedLang;
  }

}


