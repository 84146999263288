import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { Query } from 'src/app/generated/graph-ql';
import { ApolloQueryResult } from '@apollo/client/core';


@Injectable({
  providedIn: "root"
})
export class ApiService {
  private serviceUrl: string = environment.url;
  private attachmentUrl: string;
  Url: string
  queryData: Subscription;
  selectedQuestions: any;

  constructor(private apollo: Apollo, private http: HttpClient) {
    this.attachmentUrl = environment.appUrl;
    this.serviceUrl = environment.url;
    this.Url = environment.elasticSearchUrl
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  postQuery(QUERY: any, VARS: any): Observable<ApolloQueryResult<Query>> {

    return this.apollo.watchQuery<any>({
      query: QUERY,
      variables: VARS
    })
      .valueChanges;
  }

  postMutate(QUERY: any, VARS: any) {

    return this.apollo.mutate<any>({
      mutation: QUERY,
      variables: VARS
    });
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(this.serviceUrl + path, { params })
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(this.serviceUrl + path, body)
      .pipe(catchError(this.formatErrors));
  }

  localGet(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(path, { params })
      .pipe(catchError(this.formatErrors));
  }

  upload(path: string, formData: Object = {}): Observable<any> {
    return this.http
      .post(`${this.attachmentUrl}${path}`, formData, { responseType: 'text' })
      .pipe(catchError(this.formatErrors));
  }

  loadXML(path: string): Observable<any> {
    return this.http.get(path, { headers: new HttpHeaders().set('content-type', 'application/xml'), responseType: 'text' }).pipe(catchError(this.formatErrors));
  }

  getTenants(PageNumber: number, PageSize: number, searchfilter: any): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/Tenants' + '?PageNumber=' + PageNumber + '&PageSize=' + PageSize + '&search=' + searchfilter)
  }
  getYears(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetYears');
  }
  getStates(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetStates');
  }
  getTimeZones(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetTimeZones');
  }
  addTenant(tenant, scoreSearchType, zoomApiKey, zoomApiSecret, isEdisonRedirect): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/Tenant?scoreSearchType=' + scoreSearchType + '&zoomApiKey=' + zoomApiKey + '&zoomApiSecret=' + zoomApiSecret + '&isEdisonRedirect=' + isEdisonRedirect, tenant);
  }
  getTenantById(tenantId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/Tenant/' + tenantId)
  }
  getTenantservices(tenantId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetTenantServices?tenantId=' + tenantId)
  }
  getTenantFeatures(tenantId, entityType): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetTenantFeatures?tenantId=' + tenantId + '&entityType=' + entityType)
  }
  getNetworkTypes(tenantId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetNetworkTypes?tenantId=' + tenantId)
  }
  uploadTenantLogo(imageFile: File, tenantId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', imageFile, imageFile.name);
    return this.http.post(this.serviceUrl + '/api/Admin/UploadTenantLogo?tenantId=' + tenantId, formData)
  }
  saveWeightageCategoriesList(tenantId, lstWeightageCategories): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/saveWeightageCategoriesList?tenantId=' + tenantId, lstWeightageCategories)
  }
  getWeightageCategories(tenantId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetWeightageCategories?tenantId=' + tenantId)
  }
  saveWeightageCategories(tenantId, weightageCategoryID, name, weightage): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/saveWeightageCategories?tenantId=' + tenantId + '&weightageCategoryID=' + weightageCategoryID + '&name=' + name + '&weightage=' + weightage, {})

  }
  getWeighatgeCategory(weightageCategoryId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetWeighatgeCategory?weightageCategoryID=' + weightageCategoryId)
  }
  getLTIContentProviders(tenantId): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Admin/GetLTIContentProviders?tenantID=' + tenantId)
  }
  saveLtiContentProvider(data): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/SaveLtiContentProvider', data)
  }
  saveThirdPartyService(tenantID, thirdPartyServiceID, name, clientID, clientSecret): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/saveThirdPartyService?tenantID=' + tenantID + '&thirdPartyServiceID=' + thirdPartyServiceID + '&name=' + name + '&clientID=' + clientID + '&clientSecret=' + clientSecret, {})
  }
  updateFeatureToTenant(tenantID, featureID, type, status): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/updateFeatureToTenant?tenantID=' + tenantID + '&featureID=' + featureID + '&type=' + type + '&status=' + status, {})
  }
  updateDefaultToTenant(tenantID, featureID, defaultID, status): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/updateDefaultToTenant?tenantID=' + tenantID + '&featureID=' + featureID + '&defaultID=' + defaultID + '&status=' + status, {})
  }
  downloadImage(downloadId): Observable<any> {
    return this.http.post(this.serviceUrl + '/api/Admin/Download?downloadId=' + downloadId, {})
  }
  getCountries(): Observable<any> {
    return this.http.get("./assets/json/countries.json");
  }

  getQuestionSearch(pageSize: any, from: any, serachText: any, facetItem: any, sort: any,index: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.Url + index + '/_search?size=' + pageSize + '&track_total_hits=' + true + '&from=' + from, JSON.stringify({ "sort": sort, "query": { "bool": serachText }, "aggs": facetItem }), { headers: headers })
  }

  getTestSearch(pagesize: any, from: any, serachText: any, facetItem: any, sort: any, testSearchType: any, index: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.Url + index + '/_search?size=' + pagesize + '&track_total_hits=' + true + '&from=' + from, JSON.stringify({ "sort": sort, "query": { "bool": serachText }, "aggs": facetItem }), { headers: headers })
  }
  getActivitySearch(pageSize: any, from: any, serachText: any, facetItem: any, sort: any,index: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.Url + index + '/_search?size=' + pageSize + '&track_total_hits=' + true + '&from=' + from, JSON.stringify({ "sort": sort, "query": { "bool": serachText }, "aggs": facetItem }), { headers: headers })
  }

 
}


