import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FPTextboxComponent } from './components/fp-textbox/fp-textbox.component';
import { MaterialModule } from 'src/app/material-module';
import { FpDropdownComponent } from './components/fp-dropdown/fp-dropdown.component';
import { FpCkeditorComponent } from './components/fp-ckeditor/fp-ckeditor.component';
import { FpsearchComponent } from './components/fp-search/fpsearch.component';
import { searchPipe} from './search_pipe/search-pipe.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [
    FPTextboxComponent,
    FpDropdownComponent,
    FpCkeditorComponent,
    FpsearchComponent,
    searchPipe
  ],
  
  exports:[FPTextboxComponent,FpDropdownComponent,FpCkeditorComponent,FpsearchComponent,searchPipe],
  entryComponents: [
    FPTextboxComponent,
    FpDropdownComponent,FpCkeditorComponent,FpsearchComponent]
})
export class FormModule {}
