import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'fp-textbox',
  styleUrls: ['fp-textbox.component.scss'],
  template: `
 
  <div [formGroup]="formName">
  <mat-form-field class="form-field" >
  <mat-label>{{placeholder}}
  </mat-label>
  <input matInput type="{{inputType}}" formControlName="{{controlName}}" [attr.disabled] ="isDisabled">
  <mat-error *ngIf="(formName.controls[controlName].touched && formName.controls[controlName].invalid) || (submitted && formName.controls[controlName].invalid)">
  <span *ngIf="formName.controls[controlName].errors.required">This field is mandatory.</span>
  <span *ngIf="formName.controls[controlName].errors.pattern">This field is invalid.</span>
</mat-error>
</mat-form-field>
</div>

  `
})
export class FPTextboxComponent implements OnInit  {
  @Input()  errorMsg:string;
  @Input() required:string;
  @Input() placeholder:string;
  @Input() isDisabled:any;
  @Input() isReadonly:string;
  @Input()  inputLength:string;
  @Input() inputType:string;
  @Input() formName:FormGroup;
  @Input()  controlName:string;
  @Input() controlId:string;
  @Input() validateErrorMsg:string;
  @Input() minValue:string;
  @Input() submitted:boolean = false;
  ngOnInit(): void {
    // console.log(this.formName)
  }
}
