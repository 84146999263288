import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy


} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';

import { Subscription } from 'rxjs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Direction } from '@angular/cdk/bidi';
import { AppsettingsService } from 'src/app/core/services/appsettings.service';
import { GET_TENANT_APPS, GET_TENANT_APP_Modules } from 'src/app/generated/queries';
import { ApiService } from 'src/app/core/services';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  mobileQuery2: MediaQueryList;
  isSideNavOpen: boolean = true;
  dir :Direction= 'ltr';
  green = true;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  horizontal = false;
  url = '';
  sidebarOpened = false;
  status = false;
  loggedInUserName:any;
  userprofile:any;

  public showSearch = false;
  
  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  clickEvent() {
    if(this.mobileQuery2.matches){
      this.minisidebar = false;
      this.isSideNavOpen = !this.isSideNavOpen;
    }
    else{
      
      this.minisidebar = !this.minisidebar;
    }
  }


  constructor(
    public router: Router,
    private authService:AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private appsettings:AppsettingsService,
    private apiService:ApiService
    
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 280px)'); this.mobileQuery2 = media.matchMedia('(max-width: 770px)');

    if(this.mobileQuery2.matches){
      this.isSideNavOpen = false;
    }
    else{
      this.isSideNavOpen = true;
    }

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.loggedInUserName = "";
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.userprofile = this.appsettings.userProfile ?this.appsettings.userProfile:this.appsettings.getUserProfile();
    this.minisidebar = (this.userprofile && this.userprofile.StudentID>0)? true:false;
    //this.loggedInUserName =this.userprofile
   // this.authService.getLoggedInName.subscribe(name => this.loggedInUserName = name);
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
    
  }


  // Mini sidebar
}
