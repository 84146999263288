import { Injectable } from '@angular/core';

@Injectable({
    'providedIn': 'root'
})
export class SessionService {
    assessmentQuestionIndex = 0;
    attemptId = 0;

    constructor() { }

    get preferences() {
        const data = sessionStorage.getItem('preferences') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    set preferences(data: any) {
        sessionStorage.setItem('preferences', JSON.stringify(data));
    }

    get headerInfo() {
        const data = sessionStorage.getItem('headerInfo') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    set headerInfo(data: any) {
        sessionStorage.setItem('headerInfo', JSON.stringify(data));
    }

    get testType() {
        const data = sessionStorage.getItem('testType') || '{}';
        return JSON.parse(data);
    }

    set testType(data: any) {
        sessionStorage.setItem('testType', JSON.stringify(data));
    }
   

}