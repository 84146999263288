import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TenantApp } from 'src/app/generated/graph-ql';
import { ApiService } from './common/api.service';
@Injectable({
  providedIn: 'root'
})
export class AppsettingsService {
  private appConfig: any;
  userProfile: any;
  tenantApps: Array<TenantApp>
  tenantAppModules: Array<TenantApp>
  userFullName: any;
  IsAdminRequest: boolean;
  isValidRequest: boolean;
  constructor(private apiService: ApiService, private route: ActivatedRoute,) {
  }

  getUserProfile() {
    var data = localStorage.getItem('appSettings');
    this.userProfile = Object.assign({}, JSON.parse(data))
    return this.userProfile;
  }

  setUserProfile(data: any) {
    localStorage.setItem('appSettings', JSON.stringify(data));
    this.getUserProfile();
  }
  convertUTCToTenantTimeZone(datetime) {
    var targetTime = new Date(datetime);
    var utcoffset = '@(Utility.UTCOffset)';
    utcoffset = utcoffset.split(" ")[1];
    var hours = Number(utcoffset.split(":")[0]);
    var minutes = 0;
    if (utcoffset.split(":")[1] != undefined)
      minutes = Number(utcoffset.charAt(0) + utcoffset.split(":")[1]);
    var tzDifference = hours * 60 + 1 * minutes + targetTime.getTimezoneOffset();
    var offsetTime = new Date(moment((targetTime.getTime() + tzDifference * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss'))
    return offsetTime;
  }
  convertTenantTimeZoneToUTC(datetime) {
    var targetTime = new Date(datetime);
    var utcoffset = '@(Utility.UTCOffset)';

    utcoffset = utcoffset.split(" ")[1];
    var hours = Number(utcoffset.split(":")[0]);
    var minutes = 0;
    if (utcoffset.split(":")[1] != undefined)
      minutes = Number(utcoffset.charAt(0) + utcoffset.split(":")[1]);
    var tzDifference = ((hours) * 60) + 1 * minutes + targetTime.getTimezoneOffset();
    var offsetTime = new Date(moment((targetTime.getTime() - (tzDifference * 60 * 1000))).format('YYYY-MM-DD HH:mm:ss')).toUTCString();
    return offsetTime;
  }
  isAdminRequest = function () {
    this.userProfile = this.userProfile ? this.userProfile : this.getUserProfile();
    this.isValidRequest = true
    if (window.location.pathname.includes('tenant')) {
      if (window.location.pathname.includes(atob(this.userProfile.UserAccessKey))) {
        this.IsAdminRequest = true;
      }
      else {
        this.IsAdminRequest = false;
        this.isValidRequest = false;
      }
    }
    // this.route.paramMap.subscribe(params => {
    //   if (params && params.get('userKey')) {
    //     if (this.userProfile) {
    //       if (atob(this.userProfile.UserAccessKey) == params.get('userKey')) {
    //         this.IsAdminRequest = true;
    //       }
    //       else {
    //         this.IsAdminRequest = false;
    //         this.isValidRequest = false;
    //       }
    //     }
    //   }
    // })
    return this.IsAdminRequest;
  }
  isValidateModule = function (code) {
    this.userProfile = this.userProfile ? this.userProfile : this.getUserProfile();
    if (!code) {
      return true;
    }
    var codeArray = code.split('|')

    // IsAdminRequest
    if (this.IsAdminRequest) {
      return true;
    }
    else {
      if (this.userProfile.ParentID > -1 && this.userProfile.StudentID > -1) {
        if (this.tenantApps && this.tenantApps.length > 0) {
          var isvalid = false;
          codeArray.forEach((code, index) => {
            isvalid = this.tenantApps.filter(item => item.app.code == code).length > 0 ? true : false;
          })

        }
        return isvalid
      }
      else {
        var isValidModule = false;
        if (code.toLowerCase() == "coursedesigner" && this.userProfile.IsCourseDesigner) {
          isValidModule = true;
        }
        else {
          codeArray.forEach((v, index) => {
            var items = this.tenantAppModules.filter(item => item.app.code == v);
            if (items && items.length > 0)
              isValidModule = true;
          })
        }
        return isValidModule;
      }
    }
  }


}
