export const environment = {
    production: false,
    url:"https://lmsclientapi.azurewebsites.net",
    appUrl:"https://lmsclient.azurewebsites.net",
    elasticSearchUrl:"https://lmsclientapi.azurewebsites.net/elastic/",
    qtiPlayerUrl:"https://app.backpack.education/qtiplayer/QTIPlayer/",
    ltiPlayerUrl:"https://app.backpack.education/qtiplayer/",
    identityUrl:"https://iddemo.backpack.education",
    testIndexName:"planner_tests_dev_",
    questionIndexName:"planner_questions_dev_",
    activityIndexName:"planner_ltcourses_dev_",
  };
  