import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../../core/services/auth/auth.service';
import { LMSApiService } from 'src/app/core/services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppsettingsService } from 'src/app/core/services/appsettings.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {
  userClaimsInfo:any;
  utilitySettings : any;
  public tokenForm: any = FormGroup;

  constructor(private formBuilder: FormBuilder, private authService:AuthService, private lmsApiService: LMSApiService
    ,private toastr: ToastrService, private router: Router, private appsettings : AppsettingsService) { }

  ngOnInit(): void {
    this.userClaimsInfo=this.authService.userinfo();
    this.tokenForm = this.formBuilder.group({
      token :[null],
      firstName:[null],
      lastName:[null]
    });
  }

  validate = function(){
   // this.lmsApiService.validateToken(this.tokenForm.value.token, this.tokenForm.value.firstName, this.tokenForm.value.lastName, this.userClaimsInfo.sub, "FocalpointK12").subscribe((res: any) => {
    this.lmsApiService.validateToken(this.tokenForm.value.token, this.tokenForm.value.firstName, this.tokenForm.value.lastName, "sramisetty", "FocalpointK12").subscribe((res: any) => {
      if(res > 0)
      {
        //this.lmsApiService.setUserProfile(this.userClaimsInfo.sub, "FocalPointK12").subscribe((data: any) => {
        this.lmsApiService.setUserProfile(this.userClaimsInfo.preferred_username, "FocalPointK12").subscribe((data: any) => {
          this.utilitySettings = data;
        //  localStorage.setItem('_loginUsere', JSON.stringify(this.utilitySettings));
         this.appsettings.setUserProfile(this.utilitySettings);
         debugger;
          this.router.navigate(['lp/courses']);
        });
      }
      else{
      this.toastr.error("Invalid Token");
      }
    });

  }

}
